import React from "react";
// import ContactForm from '../contact-form/ContactForm'

// CUSTOM CSS IMPORT
import "./contactUs.css";

const ContactUs = () => {
  return (
    <>
      {/* CONTACT US HEADING */}
      <div className="Header">
        {/* <img className='img-fluid' src="./images/page-header/contact-page-header.jpg" alt="loading..." style={{ width: "100%" }} /> */}
        <div className="centered" id="heading-centered">
          <h1 style={{ color: "black" }}> Contact Us</h1>
          <hr style={{ opacity: "1", color: "#ffbb33", width: "100px" }} />
        </div>
      </div>

      {/* PAGE CONTAINER */}
      <div className="contact-us-page-container">
        <div className="container location-information mt-5">
          <div className="google-location">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15085.76577745111!2d73.0730045!3d19.0443191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7bcbc171a1816d60!2sYodiso%20India%20-%20Web%20Development%20and%20Digital%20Marketing%20Agency!5e0!3m2!1sen!2sin!4v1666346954568!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              className="google-map container"
              title="google-map-kharghar"
            ></iframe>
          </div>

          <div className="address p-3 ">
            <h2 className="text-center ">ADDRESS</h2>
            <div className="complete address pt-5">
              <p style={{ color: "#D9DDDC" }}>
                <i className="fas fa-home mr-3"></i> 1908, Kamdhenu Commerz,
                Sector-14, Kharghar, Navi Mumbai, Maharashtra - 410210
              </p>
              <p style={{ color: "#D9DDDC" }}>
                <i className="fas fa-envelope mr-3"></i> info@ssvinfra.co.in
              </p>
              <p style={{ color: "#D9DDDC" }}>
                <i className="fas fa-phone mr-3"></i> +91 - 98206 44666
              </p>
              <p style={{ color: "#D9DDDC" }}>
                <i className="fas fa-phone mr-3"></i> +91 - 96164 77007
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*contact form  */}
      <div className="contact-form mt-5">{/* <ContactForm /> */}</div>
    </>
  );
};

export default ContactUs;
