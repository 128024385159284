import React from 'react'

// Custom Css import
import ms from './Gallery.module.css'

// Import assets 
import bridge_image_01 from '../../assets/gallery/bridge_under_construction.jpeg'  
import bridge_image_02 from '../../assets/gallery/bridge_under_construction_01.jpeg'  
import bridge_image_03 from '../../assets/gallery/bridge_under_construction_02.jpeg'  
import bridge_image_04 from '../../assets/gallery/bridge_under_construction_03.jpeg'  
import bridge_image_05 from '../../assets/gallery/bridge_under_construction_04.jpeg'  
import bridge_image_06 from '../../assets/gallery/bridge_under_construction_05.jpeg'  
import bridge_image_07 from '../../assets/gallery/bridge_under_construction_06.jpeg'  
import bridge_image_08 from '../../assets/gallery/bridge_under_construction_07.jpeg'  
import bridge_image_09 from '../../assets/gallery/bridge_under_construction_08.jpeg'  
import bridge_image_10 from '../../assets/gallery/bridge_under_construction_09.jpeg'  
import bridge_image_11 from '../../assets/gallery/bridge_under_construction_10.jpeg'  
import bridge_image_12 from '../../assets/gallery/bridge_under_construction_11.jpeg'  
import road_image_01 from '../../assets/gallery/road_under_construction.jpeg'  
import road_image_02 from '../../assets/gallery/road_under_construction_01.jpeg'  
import road_image_03 from '../../assets/gallery/road_under_construction_02.jpeg'  



const Gallery = () => {
  return (
    <div className={`${ms.page_main_container} container`}>
      <div className={`${ms.page_heading_container}`}>
        <h2 className={`text-center`}>Projects Gallery</h2>
        <hr />
      </div>
      {/* the actual gallery of the projects */}

      <div className={`${ms.gallery_section_container} container pt-3, pb-3`}>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_01} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_02} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_03} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_04} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_05} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_06} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_07} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_08} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_09} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_10} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_11} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={bridge_image_12} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={road_image_01} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={road_image_02} alt="" />
        </div>
        <div className={`${ms.image_container}`}>
          <img src={road_image_03} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Gallery