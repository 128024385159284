import React from "react";

// Custom CSS Import
import ms from "./HealthAndSafety.module.css";

const HealthAndSafety = () => {
  return (
    <div className={`${ms.page_main_container} container`}>
      <div className={`${ms.page_heading_container}`}>
        <h2>Health & Safety</h2>
        <hr />
      </div>
      <div>
        <h6 className="">Commitment to Quality:</h6>
        <p>
          We have a steadfast commitment to delivering exceptional quality in
          every aspect of our work. This commitment extends to all our projects,
          whether it's infrastructure development or mining activities. We
          understand the importance of quality assurance in meeting and
          exceeding our clients' expectations.
        </p>
        <h6 className="">Quality Control Procedures:</h6>
        <p>
          Our organization has implemented stringent quality control procedures
          to ensure that our projects adhere to the highest industry standards.
          We employ rigorous inspection and testing protocols at every stage of
          our operations, guaranteeing the quality and durability of our
          deliverables.
        </p>
        <h6 className="">Client Satisfaction:</h6>
        <p>
          Our organization has implemented stringent quality control procedures
          to ensure that our projects adhere to the highest industry standards.
          We employ rigorous inspection and testing protocols at every stage of
          our operations, guaranteeing the quality and durability of our
          deliverables.
        </p>
        <h6 className="">Skilled Team and Experienced Managers:</h6>
        <p>
          Our strong and experienced team, led by capable managers, plays a
          pivotal role in our quality assurance efforts. They are well-versed in
          industry best practices and ensure that quality is embedded into every
          aspect of our projects.
        </p>
        <h6 className="">Modern Equipment and Efficiency:</h6>
        <p>
          We have invested in a fleet of modern equipment, to ensure that our
          projects are executed efficiently and within stipulated timeframes.
          This not only enhances our operational efficiency but also contributes
          to the overall quality of our work.
        </p>
        <h6 className="">Health and Safety as a Top Priority:</h6>
        <p>
          At our organization, the health and safety of our employees, partners,
          and well-prepared to operate in a safe and responsible manner. Our
          employees are constantly aware of the importance of safety and take
          every precaution to prevent accidents and injuries.
        </p>
        <h6 className="">Risk Assessment and Mitigation:</h6>
        <p>
          Before every project, we conduct risk assessments to identify
          potential hazards and develop strategies to mitigate them. These
          assessments help us proactively address safety concerns and ensure the
          well-being of everyone involved.
        </p>
        <h6 className="">Incident Reporting and Improvement:</h6>
        <p>
          We have a well-established incident reporting system that encourages
          open communication and reporting of any safety concerns or near
          misses. This allows us to continuously improve our safety measures and
          prevent future incidents.
        </p>
      </div>
    </div>
  );
};

export default HealthAndSafety;
