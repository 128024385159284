import React from 'react'

// Custom CSS import
import './socialMedia.css'

// Import Assets/Images
import facebookIcon from '../../assets/icons/png_icons/facebook.png'
import instagramIcon from '../../assets/icons/png_icons/instagram.png'
import linkedinIcon from '../../assets/icons/png_icons/linkedin.png'

const SocialMedia = () => {
    return (
        <>
            <div className='social-media-container d-flex gap-2'>
                <a
                    href="https://www.facebook.com"
                    target='_blank'
                    rel="noreferrer">
                    <img src={facebookIcon} className="d-block " style={{ height: '30px', width: 'auto' }} alt="..." /></a>
                <a
                    href="https://www.instagram.com"
                    target='_blank'
                    rel="noreferrer">
                    <img src={instagramIcon} className="d-block " style={{ height: '30px', width: 'auto' }} alt="..." /></a>
                <a
                    href="https://www.linkedin.com/"
                    target='_blank'
                    rel="noreferrer">
                    <img src={linkedinIcon} className="d-block " style={{ height: '30px', width: 'auto' }} alt="..." /></a>
            </div>
        </>
    )
}

export default SocialMedia
