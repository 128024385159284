import React from "react";

// Custom css import
import ms from "./OngoingProjects.module.css";
import ComingSoon from "../coming_soon/ComingSoon";

const OngoingProjects = () => {
  return (
    <>
      <div className={`${ms.page_main_container} container`}>
        <div className={`${ms.page_heading_container}`}>
          <h2 className={`text-center`}>Our Ongoing projects</h2>
          <hr />
        </div>
      </div>
      <ComingSoon />
    </>
  );
};

export default OngoingProjects;
