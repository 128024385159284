import React from "react";
import { Link } from "react-router-dom";

// CUSTOM CSS IMPORT
import ms from "./footer.module.css";

// Custom assets import
import companyLogo from "../../assets/logo/logo.png";
import homeIcon from "../../assets/icons/png_icons/home.png";
import emailIcon from "../../assets/icons/png_icons/email.png";
import phoneIcon from "../../assets/icons/png_icons/call.png";

const Footer = () => {
  return (
    <>
      <div className={`${ms.page_main_container}`}>
        <div className={`${ms.footer_column_container}`}>
          <div className={`${ms.col_container} ${ms.first_column}`}>
            <img src={companyLogo} alt="Sourabh Singh Verma" />
            <p>
              At Sourabh Singh Verma, we are committed to providing high-quality
              industrial infrastructure development services that meet the
              unique needs and demands of our clients. With a focus on
              innovation, efficiency, and sustainability, we work tirelessly to
              deliver projects that exceed expectations and help our clients
              succeed.
            </p>
          </div>
          <div className={`${ms.col_container} ${ms.second_column}`}>
            <h2>Quick Links</h2>
            <Link to="/about_us">About Us</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/governance">Governance</Link>
            <Link to="/health_and_safety">Health And Safety</Link>
            <Link to="/contact_us">Contact Us</Link>
          </div>
          <div className={`${ms.col_container} ${ms.third_column}`}>
            <h2>Contact</h2>
            <div className={`${ms.row_contents}`}>
              <img src={homeIcon} alt="" />
              <p>Kharghar, Navi Mumbai</p>
            </div>
            <div className={`${ms.row_contents}`}>
              <img src={emailIcon} alt="" />
              <p>info@ssvinfra.co.in</p>
            </div>
            <div className={`${ms.row_contents}`}>
              <img src={phoneIcon} alt="" />
              <p>+91 - 98206 44666</p>
            </div>
            <div className={`${ms.row_contents}`}>
              <img src={phoneIcon} alt="" />
              <p>+91 - 96164 77007</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
