import React from "react";

// Custom CSS Import
import ms from "./AboutUs.module.css";

// Custom Assets Import
import about_eng from "../../assets/about_page/eng_tem.jpg";

const AboutUs = () => {
  return (
    <div className={`${ms.page_main_container} container`}>
      <div className={`${ms.page_heading_container}`}>
        <h2>About Us</h2>
        <hr />
      </div>

      <div className={`{ms.about_page_contents}`}>
        <div className={`${ms.section_heading}`}>
          <h2>About Sourabh Singh Verma</h2>
          <hr />
        </div>
        <div className={`${ms.page_contents}`}>
          <div className={`${ms.left_right_section_1}`}>
            <div className={`${ms.left_image}`}>
              <img src={about_eng} alt="About us" />
            </div>
            <div className={`${ms.right_contents}`}>
              <p>
                At Sourabh Singh Verma, we are committed to providing
                high-quality industrial infrastructure development services that
                meet the unique needs and demands of our clients. With a focus
                on innovation, efficiency, and sustainability, we work
                tirelessly to deliver projects that exceed expectations and help
                our clients succeed.
              </p>
              <p>
                At Sourabh Singh Verma, we are committed to providing
                high-quality industrial infrastructure development services that
                meet the unique needs and demands of our clients. With a focus
                on innovation, efficiency, and sustainability, we work
                tirelessly to deliver projects that exceed expectations and help
                our clients succeed.
              </p>
            </div>
          </div>
        </div>
        <div className={`${ms.page_contents}`}>
          <div className={`${ms.left_right_section_2}`}>
            <div className={`${ms.right_contents}`}>
              <p>
                At Sourabh Singh Verma, we are committed to providing
                high-quality industrial infrastructure development services that
                meet the unique needs and demands of our clients. With a focus
                on innovation, efficiency, and sustainability, we work
                tirelessly to deliver projects that exceed expectations and help
                our clients succeed.
              </p>
              <p>
                At Sourabh Singh Verma, we are committed to providing
                high-quality industrial infrastructure development services that
                meet the unique needs and demands of our clients. With a focus
                on innovation, efficiency, and sustainability, we work
                tirelessly to deliver projects that exceed expectations and help
                our clients succeed.
              </p>
            </div>
            <div className={`${ms.left_image}`}>
              <img src={about_eng} alt="About us" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
