import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import the routes components
import Navbar from "./core/navbar/Navbar";
import Home from "./core/home-page/Home";
import FooterCallToAction from "./core/footerCallToAction/FooterCallToAction";
import Footer from "./core/footer/Footer";
import ContactUs from "./core/contact-us/ContactUs";
import AboutUs from "./core/about_us/AboutUs";
import OngoingProjects from "./core/ongoing_projects/OngoingProjects";
import CompletedProjects from "./core/completed_projects/CompletedProjects";
import Gallery from "./core/gallery/Gallery";
import HealthAndSafety from "./core/healthAndSafety/HealthAndSafety";
import Governance from "./core/governance/Governance";

const ProjectRoutes = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about_us" element={<AboutUs />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/ongoing-projects" element={<OngoingProjects />} />
          <Route
            exact
            path="/completed-projects"
            element={<CompletedProjects />}
          />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/health_safety" element={<HealthAndSafety />} />
          <Route exact path="/governance" element={<Governance />} />
        </Routes>
        <Footer />
        <FooterCallToAction />
      </Router>
    </>
  );
};

export default ProjectRoutes;
