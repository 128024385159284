import React from "react";
import ComingSoon from "../coming_soon/ComingSoon";

const Governance = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default Governance;
