import React from "react";

// custom styles
import s from "./ComingSoon.module.css";

const ComingSoon = () => {
  return (
    <div className={`${s.mainContents}`}>
      <h2 className="text-2xl text-white">Coming Soon...</h2>
    </div>
  );
};

export default ComingSoon;
