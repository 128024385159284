import React from 'react'

// custom CSS import
import './navbar.css'

// react modules import
import { Link } from "react-router-dom";

// Custom Components import
import SocialMedia from '../socialMedia/SocialMedia'

// Assets import
import companyLogo from '../../assets/logo/logo.png'

const Navbar = () => {
    return (
        <>
            <div className="navbar-container">
                <nav className="navbar navbar-expand-lg navbar-dark bg-light ">
                    <div className="navbar-items-wrapper container">
                        <div className="logo-section">
                            <Link className="navbar-brand" to="/"><img className='navbar-logo' src={companyLogo} alt="loading..." /></Link>

                        </div>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 navbar-spacing">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/about_us" tabIndex="-1" aria-disabled="true">About Us</Link>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Projects
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item" to="/ongoing-projects">Ongoing Projects</Link></li>
                                        <li><Link className="dropdown-item" to="/completed-projects">Completed Projects</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/governance" tabIndex="-1" aria-disabled="true">Governance</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " to="/health_safety" tabIndex="-1" aria-disabled="true">Health Safety</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Media
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item" to="/gallery">Gallery</Link></li>
                                        {/* <li><Link className="dropdown-item" to="/video">Video</Link></li> */}
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link " to="/contact" tabIndex="-1" aria-disabled="true">Contact Us</Link>
                                </li>

                            </ul>
                            <form className="d-flex">
                                <SocialMedia />
                                {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-success" type="submit">Search</button> */}
                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar
