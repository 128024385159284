import React from "react";

// Custom Components import
import HomeCarousel from "./home-carousel/HomeCarousel";
const Home = () => {
  return (
    <>
      {/* HOME CAROUSEL */}
      <HomeCarousel />

      <h2 className="text-center mt-3">ABOUT US</h2>
      <hr
        style={{
          opacity: "1",
          color: "#ffbb33",
          width: "100px",
          height: "0.2rem",
          margin: "auto",
        }}
      />
      <div className="page-container container p-3">
        <h4>About Sourabh Singh Verma</h4>
        <p>
          We wish to introduce ourselves as a pioneer in executing
          Infrastructure Projects as well as mining activity. We are based in
          District: Pali, in the state of Rajasthan.
        </p>
        <p>
          We believe in delivering Quality in every aspect of work & are well
          committed towards our works. During the past couple of years we have
          taken-up various contracts and proved our capability & earned the
          satisfaction of our clients.
        </p>
        <p>
          We possess a strong team led by well experienced Manager's to
          carry-out works in a well-to-do manner. We also have a fleet of modern
          equipment's for the most efficient way and in stipulated time period.
        </p>

        <h4>Our Services</h4>
        <p>
          We specialize in a range of industrial infrastructure development
          services, including company infrastructure construction, road
          construction, and much more. Our team of experienced professionals has
          the skills and expertise necessary to take on even the most complex
          projects, and we are committed to delivering exceptional results on
          time and on budget.
        </p>
        <p>
          At Sourabh Singh Verma, we believe that our success is built on a
          foundation of strong values. We are committed to honesty, integrity,
          and transparency in everything we do, and we work tirelessly to build
          strong, long-lasting relationships with our clients. We also believe
          in the importance of innovation and sustainability, and we are
          constantly looking for ways to improve our processes and practices to
          better serve our clients and protect the environment.
        </p>
        <h4>Our Mission</h4>
        <p>
          At Sourabh Singh Verma, our mission is to help our clients achieve
          their goals by providing top-quality infrastructure development
          services that exceed expectations. We are dedicated to building strong
          relationships with our clients, and we strive to be a trusted partner
          in their success.
        </p>
        <h4>Our Mission</h4>
        <p>
          When you choose Sourabh Singh Verma, you can rest assured that you are
          working with a team of professionals who are committed to your
          success. With our focus on innovation, efficiency, and sustainability,
          we are uniquely positioned to deliver projects that meet your needs
          and help you achieve your goals. Contact us today to learn more about
          how we can help your business succeed.
        </p>
      </div>
    </>
  );
};

export default Home;
